import React, { useEffect, useRef, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Video = ({ src }) => {
    const refVideo = useRef()
    const refSrc = useRef()
    const [playing, setPlaying] = useState(false)
    
    useEffect(() => {
        if (typeof window !== 'undefined' && typeof document !== 'undefined') {
            refVideo.current.addEventListener('loadeddata', () => {
                setPlaying(true)
            })
            refVideo.current.addEventListener('ended', () => {
                
            })
            refVideo.current.addEventListener('timeupdate', (e) => {
                let hh = parseInt(refVideo.current?.currentTime / (60*60), 10);
                let mm = parseInt(refVideo.current?.currentTime / 60, 10);
                let ss = parseInt(refVideo.current?.currentTime % 60);
                let time = hh +':'+ mm +':'+ ss
            })
            refSrc.current.src = src;
            refVideo.current.load();
        }
    }, [])

    useEffect(() => {
        if (playing) {
            refVideo.current.play()
        }
    }, [playing])

    return (
        <>
            <video ref={ refVideo } preload="none" playsInline muted  
                className="object-contain vid-media">
                <source ref={ refSrc } type="video/mp4" />
            </video>
            {!playing && <StaticImage src="../images/TRUST-RH-Choices-2023-KV.jpg" alt="Hero Poster" 
                style={{
                    position:'absolute',
                    inset:0,
                    objectFit:'cover'
                }}
            />}
        </>
    )
}

export default Video
