import React, { useEffect, useRef, useImperativeHandle, forwardRef } from 'react'
import lottie from 'lottie-web'
import midBoxData from '../assets/MidBox.json'

import styled from 'styled-components'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../tailwind.config'


const twConfig = resolveConfig(tailwindConfig)

const MidBox = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 960px;
    @media (min-width: ${twConfig.theme.screens.md}) {
        width:1920px;
    }
`

const AnimatedMidBox = forwardRef((props, ref) => {
    const refMidBox = useRef()
    let lottieInstance = useRef()

    useEffect(() => {
        if (lottieInstance.current == null) {
            lottieInstance.current = lottie.loadAnimation({
                container: refMidBox.current,
                animationData: midBoxData,
                loop: false,
                autoplay: false
            })
        }
    }, [])

    useImperativeHandle(ref, () => ({
        start: function(inView) {
            if (lottieInstance.current == null) return;
            if (!!inView) {
                lottieInstance.current.setDirection(1)
            } else {
                lottieInstance.current.setDirection(-1)                
            }
            lottieInstance.current.play()
        }
    }))

    return (
        <MidBox ref={ refMidBox } />
    )
})

export default AnimatedMidBox
