import React, { useState } from 'react'
import Video from './Video'
import './Hero.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'


const SocialLink = ({ link, text, icon }) => (
    <a className="text-seance hover:text-purpleheart md:text-white md:hover:text-gray-300" href={ link } target="_blank" rel="noreferrer">
        <FontAwesomeIcon icon={ icon } size="lg" className="mr-2" />
        <span className="tracking-wider">{ text }</span>
    </a>
)

const Hero = () => {
    return (
        <div className="relative overflow-hidden bg-pattensblue">
            <div className="vid-container border">
                <Video 
                    src={`${process.env.WORDPRESS_URL}/wp-content/themes/doitright-theme/videos/TRUST-RH-Choices-2023.mp4`}
                    poster={`${process.env.WORDPRESS_URL}/wp-content/themes/doitright-theme/images/TRUST-RH-Choices-2023-KV.jpg`} 
                    />
            </div>
            <div className="md:absolute md:bottom-0 top-full md:top-auto w-full flex justify-center md:justify-between gap-10 p-0 pt-6 md:p-6">
                <SocialLink link="https://www.facebook.com/DoItRightPH" text="DoItRightPH" icon={ faFacebook }/>
                <SocialLink link="https://doitright.ph" text="doitright.ph" icon={ faGlobe }/>
            </div>
        </div>
    )
}

export default Hero
