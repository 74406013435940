import React, { useRef } from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Hero from '../../components/Hero'
import { InView } from 'react-intersection-observer'
import AnimatedMidBox from '../../components/AnimatedMidBox'
import styled from 'styled-components'
import { SocialMediaLinks } from '../../utils'
import { graphql, useStaticQuery } from 'gatsby'

import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../../../tailwind.config'

import loadable from '@loadable/component'
const OurProducts = loadable(() => import('../../components/OurProducts')) 
const Inquiry = loadable(() => import('../../components/Inquiry'))

const twConfig = resolveConfig(tailwindConfig)

const MidSection = styled.div`
    position: relative;
    width: 100%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    
    height: 620px;
    background-position: center;
    background-size: cover;
    margin-top: 0;
    background-color: ${twConfig.theme.colors.pattensblue};
    
    @media (min-width: ${twConfig.theme.screens.md}) {
        margin-top: -100px;
        height: 1300px;
    }
`

const Home = ({ pageContext, location }) => {
    const page = pageContext.page

    const refMidbox = useRef()

    const midText = {
        top: '55%',
        transform: 'translate(-50%,-50%)',
        fontFamily: 'futuraRegular',
        fontSize: '20px !important',
    }

    const data = useStaticQuery(graphql`
    query {
        favicon: file(relativePath: {eq: "logo-w431.png"}) {
            id
            childImageSharp {
                gatsbyImageData(
                    width: 431
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
                original {
                    src
                }
            }
        }
    }
    `)

    return (
        <Layout>
            <Seo title={page.title} meta={[
                { property:'og:url', content:location.href }
            ].concat(SocialMediaLinks(data.favicon?.childImageSharp.original.src))}/>

            <section>
                <Hero/>
            </section>

            <section className="bg-pattensblue" style={{ position:'relative', minHeight:'364px'}}>
                <OurProducts/>
            </section>

            <MidSection style={{ position:'relative', minHeight:'364px'}}>
                <AnimatedMidBox ref={ refMidbox } />
                <div className="absolute w-full text-center text-white uppercase left-2/4" style={ midText }>
                    <InView as="div" className="px-3 mx-auto text-sm tracking-wider md:text-2xl my-container"
                        onChange={ (inView) => refMidbox.current.start(inView) }>
                        <p>Using contraceptives is more than just a functional act. It is an act of love. It shows maturity, commitment, and consideration for the future and your partner. Using contraceptives is not something to feel embarrassed about. It’s the correct thing to do.</p>
                        <br/>
                        <p>Together, we will change the norm by removing the shame and replacing it with a sense of pride and collective belonging. Let's love well, love responsibly, and love proudly.</p>
                        <br/>
                        <p className="text-center">
                            <a href="/our-advocacy">
                                Let's <strong>'Do It Right!'</strong>
                            </a>
                        </p>
                    </InView>
                </div>
            </MidSection>

            <section className="bg-pattensblue">
                <Inquiry />
            </section>

        </Layout>
    )
}

export default Home